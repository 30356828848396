










































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref, computed, inject, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import Draggable from 'vuedraggable';
import * as R from 'ramda';
import {
    XIcon,
    CheckIcon,
    DocumentIcon,
    CheckCircleIcon,
    XCircleIcon,
    RefreshIcon,
    ExclamationCircleIcon,
    ArrowCircleUpIcon,
} from '@vue-hero-icons/outline';
import Papa from 'papaparse';
import { FormBlock, HtmlModal, SvgImage, TwButton, Scrollbar } from '@/app/components';
import { useFilters } from '@/app/composable';
import store from '@/app/store';
import { GeneralPolicy, ExceptionPolicy } from '@/modules/access-policy/models';
import TreeView from '@/app/components/treeview/TreeView.vue';
import { AssetsAPI } from '../api';
import WorkflowAPI from '../../workflow-designer/api/workflow';
import { useModelRegistration } from '../composable/model-registration';
import ModelFeature from '../components/ModelFeature.vue';
import { AccessPolicy } from '../../access-policy/components';

export default defineComponent({
    name: 'RegisterModel',
    props: {},
    components: {
        FormBlock,
        AccessPolicy,
        TreeView,
        Draggable,
        ModelFeature,
        HtmlModal,
        SvgImage,
        TwButton,
        XIcon,
        CheckIcon,
        DocumentIcon,
        CheckCircleIcon,
        XCircleIcon,
        RefreshIcon,
        ExclamationCircleIcon,
        ArrowCircleUpIcon,
        Scrollbar,
    },
    setup(props, { root, emit }) {
        const { formatBytes } = useFilters();
        const isFeatureEnabled: any = inject('isEnabled');
        const metadata = {
            general: true,
            distribution: true,
            extent: true,
            licensing: true,
            pricing: true,
            model: true,
        };
        const axiosRunner = useAxios(true);
        const asset = ref<any>();
        const models = ref([]);
        const platformModels = ref([]);
        const modelNames = ref<string[]>([]);
        const sampleRef = ref<any>(null);
        const encodedFeature = ref<string>('');
        const additionalData = ref<any>({});

        const {
            accessLevel,
            copyrightOwner,
            customLicense,
            getLicensingSchema,
            getPricingSchema,
            initAsset,
            accessLevelOptions,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            listenToWorkflow,
            showModelValidationModal,
            modelValidationStatus,
            canCloseModal,
            modalImage,
            modalTitle,
            modalDescription,
            librarySelected,
            modelStructure,
            checkLicense,
        } = useModelRegistration(asset);

        asset.value = initAsset(metadata);

        axiosRunner
            .exec(WorkflowAPI.getModels())
            .then((allPlatformModels: any) => {
                axiosRunner.exec(AssetsAPI.getModelAssets()).then((assetModels: any) => {
                    models.value = allPlatformModels.data;
                    const registeredModelsNames = assetModels.data.map((assetModel: any) => assetModel.name);
                    // Filter out user-uploaded models and models already registered
                    platformModels.value = models.value
                        .filter(
                            (model: any) =>
                                !model.attributes.isUploadedByUser && !registeredModelsNames.includes(model.filename),
                        )
                        .sort((a: any, b: any) => a.filename.localeCompare(b.filename));
                    modelNames.value = models.value.map((model: any) => model.filename);
                });
            })
            .catch(() => {
                (root as any).$toastr.e('Models could not be retrieved!', 'Error');
            });

        const registerNames = computed(() => {
            if (modelNames.value) {
                return modelNames.value
                    .filter(
                        (model: string) =>
                            asset.value.source !== 'Platform' || model !== asset.value.metadata.model.name,
                    )
                    .join(',');
            }
            return '';
        });

        const modelOptions = computed(() => {
            if (platformModels.value) {
                return platformModels.value.reduce((obj: any, model: any) => {
                    return { ...obj, [model.filename]: model.filename };
                }, {});
            }
            return [];
        });

        const modelFile = ref<any>();
        const sampleFile = ref<any>();
        const sample = ref<any>();

        const validForms = ref<any>({
            model: false,
            modelValidation: false,
            general: false,
            accessLevelCopyrightOwner: false,
            licensing: false,
            pricing: false,
        });

        const user = computed(() => store.state.auth.user);
        const showLicensing = computed(
            () => isFeatureEnabled('asset.metadata.license') || isFeatureEnabled('access-policies'),
        );

        const accessPolicies = ref({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });

        const saveClicked = ref<boolean>(false);

        const contentRef = ref<any>(null);

        const featuresRef = ref<string[]>([]);
        const encodedFeaturesRef = ref<string[]>([]);

        const selectedFeatures = ref<string[]>([]);

        const selectedModel = ref<any>();

        const clearModelDetails = () => {
            if (registerNames.value.includes(asset.value.name) || asset.value.source === 'Platform') {
                asset.value.name = '';
            }
            asset.value.metadata.model = {
                library: '',
                type: '',
                purpose: '',
                algorithm: '',
                name: '',
            };
            selectedModel.value = null;
            featuresRef.value = [];
            selectedFeatures.value = [];
            encodedFeaturesRef.value = [];
            sampleFile.value = null;
            sample.value = null;
            modelFile.value = null;
            additionalData.value = {};
            modelValidationStatus.value = '';
        };

        const clearFilesAndFeatures = () => {
            featuresRef.value = [];
            selectedFeatures.value = [];
            encodedFeaturesRef.value = [];
            sampleFile.value = null;
            sample.value = null;
            modelFile.value = null;
            additionalData.value = {};
            modelValidationStatus.value = '';
        };

        const checkFeature = (index: number) => {
            const checkedElement = featuresRef.value[index];
            featuresRef.value.splice(index, 1);
            const firstUncheckedElement = featuresRef.value.find(
                (feature) => !selectedFeatures.value.includes(feature),
            );
            if (!firstUncheckedElement) featuresRef.value.push(checkedElement);
            else {
                const idx = featuresRef.value.indexOf(firstUncheckedElement);
                featuresRef.value.splice(idx, 0, checkedElement);
            }
            modelValidationStatus.value = '';
        };

        const uncheckFeature = (index: number) => {
            const uncheckedELement = featuresRef.value[index];
            featuresRef.value.splice(index, 1);
            featuresRef.value.push(uncheckedELement);
            modelValidationStatus.value = '';
        };

        const featureMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                featuresRef.value = R.move(oldIndex, newIndex - 1, featuresRef.value);
            } else if (oldIndex > newIndex) {
                featuresRef.value = R.move(oldIndex, newIndex + 1, featuresRef.value);
            }
            modelValidationStatus.value = '';
        };

        const encodedFeatureMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            if (oldIndex < newIndex) {
                encodedFeaturesRef.value = R.move(oldIndex, newIndex - 1, encodedFeaturesRef.value);
            } else if (oldIndex > newIndex) {
                encodedFeaturesRef.value = R.move(oldIndex, newIndex + 1, encodedFeaturesRef.value);
            }
            modelValidationStatus.value = '';
        };

        const deleteFeature = (index: number) => {
            encodedFeaturesRef.value.splice(index, 1);
            modelValidationStatus.value = '';
        };

        const addFeature = () => {
            if (!encodedFeaturesRef.value.includes(encodedFeature.value) && encodedFeature.value !== '') {
                encodedFeaturesRef.value.push(encodedFeature.value);
                encodedFeature.value = '';
                modelValidationStatus.value = '';
            }
        };

        const clearFeature = () => {
            encodedFeature.value = '';
        };

        const modelSelected = async (event: any) => {
            const foundModel: any = models.value.find((model: any) => model.filename === event);
            if (foundModel) {
                const library = foundModel.attributes.type.split('-')[0];
                const type = foundModel.attributes.type.split('-')[1];
                asset.value.metadata.model = {
                    library,
                    type,
                    purpose: foundModel.attributes.purpose,
                    name: foundModel.filename,
                    algorithm: foundModel.attributes.name,
                };
                asset.value.name = foundModel.filename;
                featuresRef.value = foundModel.attributes.feature_order;
            }
            selectedModel.value = foundModel;
        };

        const hasEncodedFeatures = computed(() => {
            return (
                asset.value?.metadata?.model?.type === 'transformer' &&
                asset.value?.metadata?.model?.library === 'sklearn'
            );
        });

        const hasSampleAndFeatures = computed(() => asset.value?.metadata?.model?.purpose !== 'timeseries forecasting');

        const isVarModel = computed(() => asset.value?.metadata?.model?.purpose === 'timeseries forecasting var');

        const hasCompletedModelDetails = computed(() => {
            return (
                asset.value?.metadata?.model?.library !== '' &&
                asset.value?.metadata?.model?.type !== '' &&
                asset.value?.metadata?.model?.purpose !== '' &&
                asset.value?.metadata?.model?.algorithm !== ''
            );
        });

        const canExecuteTestRun = computed(() => {
            return (
                asset.value.name &&
                !modelNames.value.includes(asset.value.name) &&
                hasCompletedModelDetails.value &&
                (!hasSampleAndFeatures.value || (featuresRef.value.length > 0 && sampleFile.value)) &&
                (!hasEncodedFeatures.value || encodedFeaturesRef.value.length > 0) &&
                (!isVarModel.value || (additionalData.value.lag && additionalData.value.steps)) &&
                modelFile.value
            );
        });

        const modelType = computed(() => {
            if (asset.value?.metadata?.model?.library && asset.value?.metadata?.model?.type) {
                if (asset.value.metadata.model.library === 'xgboost' && asset.value.metadata.model.type === 'model') {
                    return 'sklearn-model';
                }
                if (
                    asset.value.metadata.model.library === 'statsmodel' &&
                    asset.value.metadata.model.type === 'model'
                ) {
                    return 'statsmodel';
                }
                return `${asset.value.metadata.model.library}-${asset.value.metadata.model.type}`;
            }
            return '';
        });

        const validateModel = async () => {
            const modelMetadata: any = {
                type: modelType.value,
                purpose: asset.value.metadata.model.purpose,
                name: asset.value.metadata.model.algorithm,
                displayName: asset.value.name,
                additionalData: additionalData.value,
                isUploadedByUser: true,
            };

            if (hasEncodedFeatures.value) {
                modelMetadata.features_encoded = encodedFeaturesRef.value;
            }

            if (hasSampleAndFeatures.value) {
                modelMetadata.feature_order = featuresRef.value.filter((feature: string) =>
                    selectedFeatures.value.includes(feature),
                );
            }

            const isSparkExecution = asset.value.metadata.model.library === 'mllib';

            const frameworkVersion = isSparkExecution
                ? process.env.VUE_APP_EXECUTION_FRAMEWORK_SPARK_VERSION
                : process.env.VUE_APP_EXECUTION_FRAMEWORK_PYTHON_VERSION;

            showModelValidationModal.value = true;
            canCloseModal.value = false;

            modelValidationStatus.value = 'uploading';
            axiosRunner
                .exec(
                    WorkflowAPI.validateModel(
                        asset.value.name,
                        modelMetadata,
                        frameworkVersion as string,
                        sampleFile.value,
                        modelFile.value,
                    ),
                )
                .then((response: any) => {
                    if (response.data.workflowId && response.data.executionId) {
                        modelValidationStatus.value = 'running';
                        listenToWorkflow(response.data.workflowId, response.data.executionId);
                    } else {
                        modelValidationStatus.value = 'invalid';
                        canCloseModal.value = true;
                    }
                })
                .catch(() => {
                    modelValidationStatus.value = 'invalid';
                    canCloseModal.value = true;
                });
        };

        const cancel = () => {
            root.$router.push({ name: 'assets' });
        };

        const getAccessPoliciesJSON = () => {
            const json = [];
            accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
                json.push(policy.toJSON());
            });
            json.push(accessPolicies.value.generalPolicy.toJSON());
            return json;
        };

        const saveChanges = async () => {
            root.$delete(asset.value, 'createdAt');
            root.$delete(asset.value, 'updatedAt');

            // licensing metadata
            if (showLicensing.value && metadata.licensing && asset.value.metadata.license) {
                if (!asset.value.metadata.license.derivation || asset.value.metadata.license.derivation.length === 0) {
                    asset.value.metadata.license.derivation = ['Prohibited'];
                }
                asset.value.metadata.license.accessLevel = accessLevel.value;
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;
            }
            // pricing metadata
            if (isFeatureEnabled('asset.pricing.metadata') && metadata.pricing && asset.value.metadata.pricing) {
                if (asset.value.metadata.pricing.costCurrency) {
                    asset.value.metadata.pricing.cost = Number(asset.value.metadata.pricing.costCurrency[0].cost);
                    asset.value.metadata.pricing.currency = asset.value.metadata.pricing.costCurrency[0].currency;
                }

                if (asset.value.metadata.pricing.calculationScheme === 'Request Dependent') {
                    asset.value.metadata.pricing.cost = null;
                }
            }

            asset.value.policies = getAccessPoliciesJSON();

            if (hasEncodedFeatures.value) {
                asset.value.metadata.model.encodedFeatures = encodedFeaturesRef.value;
            }

            if (hasSampleAndFeatures.value) {
                asset.value.metadata.model.featureOrder = featuresRef.value.filter((feature: string) =>
                    selectedFeatures.value.includes(feature),
                );
            }

            asset.value.metadata.model.isUploadedByUser = asset.value.source !== 'Platform';
            if (asset.value.source !== 'Platform') {
                asset.value.metadata.model.name = asset.value.name;
            }

            const payload = {
                ...R.clone(asset.value),
                distribution: {},
                structure: { otherConcepts: [], primaryConcept: null },
            };
            if (payload.metadata && payload.metadata.pricing && payload.metadata.pricing.costCurrency) {
                delete payload.metadata.pricing.costCurrency;
            }
            axiosRunner
                .exec(AssetsAPI.createAsset(payload as any))
                .then(() => {
                    (root as any).$toastr.s('Model successfully registered!', 'Success');
                    root.$router.push({ name: 'assets' });
                })
                .catch(() => {
                    (root as any).$toastr.e('Model could not be registered!', 'Error');
                });
        };

        const save = computed(() => {
            if (
                !validForms.value.general ||
                !validForms.value.model ||
                !validForms.value.modelValidation ||
                (showLicensing.value &&
                    metadata.licensing &&
                    (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing)) ||
                (isFeatureEnabled('asset.pricing.metadata') &&
                    metadata.pricing &&
                    customLicense.value &&
                    accessLevel.value !== 'Confidential' &&
                    accessLevel.value !== 'Public' &&
                    !validForms.value.pricing)
            ) {
                return false;
            }
            return true;
        });

        const submitForms = () => {
            validForms.value = {
                general: false,
                model: false,
                modelValidation: false,
                accessLevelCopyrightOwner: false,
                licensing: false,
                pricing: false,
            };
            (root as any).$formulate.submit('general');
            (root as any).$formulate.submit('model');

            if (asset.value.source === 'Upload') {
                validForms.value.modelValidation = modelValidationStatus.value === 'completed';
            } else {
                validForms.value.modelValidation = true;
            }

            if (showLicensing.value && metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (!accessLevel.value || accessLevel.value === 'Confidential') {
                    validForms.value.licensing = true;
                } else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }
            if (
                isFeatureEnabled('asset.pricing.metadata') &&
                metadata.pricing &&
                customLicense.value &&
                accessLevel.value &&
                accessLevel.value !== 'Confidential' &&
                accessLevel.value !== 'Public'
            ) {
                (root as any).$formulate.submit('pricing');
            }
        };

        const formSubmitted = (name: string) => {
            saveClicked.value = true;
            validForms.value[name] = true;
            if (save.value) {
                saveChanges();
            } else {
                contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        const duplicateHeaders = ref<boolean>(false);
        const invalidFormat = ref<boolean>(false);

        const checkInvalidCSV = async (file: any) => {
            if (!file) return;
            const data = await file.text();
            Papa.parse(data, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    // check if there are any duplicate headers
                    const headers = results.meta.fields;
                    if (headers) {
                        const uniqueHeaders = new Set(headers); // keep only the unique headers
                        duplicateHeaders.value = headers.length !== uniqueHeaders.size;
                    }

                    invalidFormat.value = !!results.errors.length; // check if there are any errors, thus invalid file format
                },
            });
        };

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const parseCSV = (file: any) => {
            Papa.parse(file, {
                header: true,
                preview: 50,
                dynamicTyping: true,
                transform: (value) => value.trim(),
                complete: (results) => {
                    sampleFile.value = file;
                    sample.value = results.data;
                    if (hasSampleAndFeatures.value) {
                        featuresRef.value = results.meta.fields as string[];
                        selectedFeatures.value = R.clone(featuresRef.value);
                    }
                },
            });
        };

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];

            modelValidationStatus.value = '';
            await checkInvalidCSV(file);
            if (invalidFormat.value || duplicateHeaders.value) {
                (root as any).$toastr.e(
                    invalidFormat.value ? 'Invalid CSV format!' : 'Duplicate columns have been detected in the file!',
                    'Error',
                );

                errorAlert.value.title = duplicateHeaders.value
                    ? `Duplicate columns have been detected in the Sample file "${file.name}"`
                    : null;
            } else {
                await parseCSV(file);
                clearErrorAlert();
            }
        };

        const modelUploaded = async (event: any) => {
            const file = event.target.files[0];
            modelFile.value = file;
            modelValidationStatus.value = '';
        };

        const validationStatusText = computed(() => {
            switch (modelValidationStatus.value) {
                case '':
                    return 'Not validated';
                case 'completed':
                    return 'Validated';
                case 'failed':
                case 'invalid':
                    return 'Validation Failed';
                default:
                    return 'Validation Pending';
            }
        });

        const resetLicenseAndPricing = (level: any) => {
            if (level) {
                if (level === 'Confidential') {
                    asset.value.metadata.license.license = null;
                    asset.value.metadata.license.copyrightOwner = null;
                    asset.value.metadata.license.link = null;
                    asset.value.metadata.license.derivation = null;
                    asset.value.metadata.license.attribution = null;
                    asset.value.metadata.license.reproduction = null;
                    asset.value.metadata.license.distribution = null;
                    asset.value.metadata.license.shareAlike = null;
                    asset.value.metadata.license.reContext = null;
                    asset.value.metadata.license.offlineRetention = null;
                    asset.value.metadata.license.targetPurpose = null;
                    if (asset.value.metadata.pricing) {
                        asset.value.metadata.pricing.cost = null;
                        asset.value.metadata.pricing.currency = null;
                        asset.value.metadata.pricing.paymentMethod = null;
                        asset.value.metadata.pricing.calculationScheme = null;
                    }
                } else if (level === 'Public' && asset.value.metadata.pricing) {
                    asset.value.metadata.pricing.cost = null;
                    asset.value.metadata.pricing.currency = null;
                    asset.value.metadata.pricing.paymentMethod = null;
                    asset.value.metadata.pricing.calculationScheme = null;
                } else if (level === 'Private' && asset.value.metadata.license.license !== 'Custom') {
                    asset.value.metadata.license.license = 'Custom';
                    checkLicense({ id: 'Custom', label: 'Custom' });
                }
            }
        };

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => {
                resetLicenseAndPricing(level);
            },
        );

        return {
            contentRef,
            accessLevel,
            copyrightOwner,
            customLicense,
            cancel,
            asset,
            metadata,
            getLicensingSchema,
            getPricingSchema,
            accessLevelOptions,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            save,
            saveClicked,
            isFeatureEnabled,
            showLicensing,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            modelUploaded,
            sampleUploaded,
            featureMoved,
            encodedFeatureMoved,
            featuresRef,
            encodedFeaturesRef,
            emit,
            models,
            modelStructure,
            modelOptions,
            selectedModel,
            modelSelected,
            librarySelected,
            modelFile,
            sampleRef,
            sampleFile,
            sample,
            formatBytes,
            validateModel,
            showModelValidationModal,
            modelValidationStatus,
            canCloseModal,
            modalImage,
            modalTitle,
            modalDescription,
            canExecuteTestRun,
            selectedFeatures,
            checkFeature,
            uncheckFeature,
            validationStatusText,
            registerNames,
            hasSampleAndFeatures,
            hasEncodedFeatures,
            deleteFeature,
            addFeature,
            encodedFeature,
            clearFeature,
            clearModelDetails,
            clearFilesAndFeatures,
            hasCompletedModelDetails,
            isVarModel,
            additionalData,
        };
    },
});
