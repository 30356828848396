






























import * as R from 'ramda';
import { ref, watch } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import { Field, FieldType } from '../constants';
import { ConditionValue } from '../models';

export default {
    name: 'AccessPolicyValueSelect',
    model: {
        prop: 'selection',
        event: 'update-selection',
    },
    props: {
        selection: Array,
        field: {
            type: Field,
            required: true,
        },
    },
    components: { Treeselect },
    setup(props: any, { emit }: { emit: any }) {
        const currentField = ref(props.field);
        const selectedValue = ref(props.selection && props.selection.length > 0 ? props.selection[0].label : []);
        const selectedValues = ref(props.selection ? R.map((sel) => sel.id, props.selection) : []);
        const values = ref([]);

        const updateSelection = () => {
            if (R.isEmpty(selectedValue.value)) {
                emit('update-selection', []);
            } else {
                emit('update-selection', [new ConditionValue(selectedValue.value, selectedValue.value)]);
            }
        };

        watch(
            () => props.field,
            (changedField) => {
                if (changedField.fieldType === FieldType.SELECT) {
                    changedField.getValues().then((result: []) => {
                        values.value = result;
                    });
                }
                if (currentField.value.key !== changedField.key) {
                    selectedValue.value = [];
                    selectedValues.value = [];
                    currentField.value = changedField;
                    updateSelection();
                }
            },
            { immediate: true },
        );

        watch(
            () => props.selection,
            (selection) => {
                selectedValue.value = selection && selection.length > 0 ? selection[0].label : [];
                selectedValues.value = selection ? R.map((sel) => sel.id, selection) : [];
            },
            { immediate: true },
        );

        const onTreeSelect = (selection: any) => {
            if (!props.selection) {
                emit('update-selection', [selection]);
            } else {
                emit('update-selection', [...props.selection, selection]);
            }
        };

        const onTreeDeselect = (selection: any) => {
            const elementIndex = props.selection.findIndex((sel: any) => sel.id === selection.id);
            if (elementIndex >= 0) {
                const newSelection = [...props.selection];
                newSelection.splice(elementIndex, 1);
                emit('update-selection', newSelection);
            }
            const parent = props.field.findParentForChildId(selection.id);
            if (parent) {
                const parentIndex = props.selection.findIndex((sel: any) => sel.id === parent.id);
                if (parentIndex >= 0) {
                    const newSelection = [...props.selection];
                    newSelection.splice(parentIndex, 1);

                    emit('update-selection', [...newSelection, ...props.field.getSiblingsForId(selection.id)]);
                }
            }
        };

        return { selectedValue, selectedValues, updateSelection, FieldType, values, onTreeSelect, onTreeDeselect };
    },
};
