































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, inject, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { XIcon, ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { FormBlock, Scrollbar } from '@/app/components';
import store from '@/app/store';
import { GeneralPolicy, ExceptionPolicy } from '@/modules/access-policy/models';
import { StatusCode } from '@/modules/asset/constants';
import { AssetsAPI } from '../api';
import { useModelRegistration } from '../composable/model-registration';
import ModelFeature from '../components/ModelFeature.vue';
import { AccessPolicy } from '../../access-policy/components';

export default defineComponent({
    name: 'EditModelAsset',
    props: {
        id: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: false,
        },
    },
    components: {
        OrbitSpinner,
        FormBlock,
        AccessPolicy,
        ModelFeature,
        XIcon,
        ExclamationCircleIcon,
        Scrollbar,
    },
    setup(props, { root }) {
        const id = computed<string>(() => props.id);

        const assetStatus = ref<string | null>(null);
        const readOnly = computed(
            () => assetStatus.value === StatusCode.Deprecated || assetStatus.value === StatusCode.Archived,
        );

        const isFeatureEnabled: any = inject('isEnabled');
        const metadata = {
            general: true,
            distribution: true,
            extent: true,
            licensing: true,
            pricing: true,
            model: true,
        };
        const loading = ref(false);
        const axiosRunner = useAxios(true);
        const asset = ref<any>();

        const pageTitle = computed(() => {
            if (props.status === 'draft') {
                return 'Publish Model:';
            }
            if (assetStatus.value) {
                switch (assetStatus.value) {
                    case StatusCode.Deprecated:
                    case StatusCode.Archived:
                        return 'View Model Details:';
                    default:
                        return 'Edit Model Asset:';
                }
            }

            return null;
        });

        const {
            accessLevel,
            copyrightOwner,
            customLicense,
            getLicensingSchema,
            getPricingSchema,
            initAsset,
            accessLevelOptions,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            checkLicense,
        } = useModelRegistration(asset, readOnly);

        asset.value = initAsset(metadata);

        const validForms = ref<any>({
            model: false,
            general: false,
            accessLevelCopyrightOwner: false,
            licensing: false,
            pricing: false,
        });

        const user = computed(() => store.state.auth.user);
        const showLicensing = computed(
            () => isFeatureEnabled('asset.metadata.license') || isFeatureEnabled('access-policies'),
        );

        const accessPolicies = ref({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });

        const saveClicked = ref<boolean>(false);

        const contentRef = ref<any>(null);

        const featuresRef = ref<string[]>([]);
        const encodedFeaturesRef = ref<string[]>([]);

        const cancel = () => {
            root.$router.push({ name: 'assets' });
        };

        const getAccessPoliciesJSON = () => {
            const json = [];
            accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
                json.push(policy.toJSON());
            });
            json.push(accessPolicies.value.generalPolicy.toJSON());
            return json;
        };

        const saveChanges = async () => {
            root.$delete(asset.value, 'createdAt');
            root.$delete(asset.value, 'updatedAt');

            // licensing metadata
            if (showLicensing.value && metadata.licensing && asset.value.metadata.license) {
                if (!asset.value.metadata.license.derivation || asset.value.metadata.license.derivation.length === 0) {
                    asset.value.metadata.license.derivation = ['Prohibited'];
                }
                asset.value.metadata.license.accessLevel = accessLevel.value;
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;
            }
            // pricing metadata
            if (isFeatureEnabled('asset.pricing.metadata') && metadata.pricing && asset.value.metadata.pricing) {
                if (asset.value.metadata.pricing.costCurrency) {
                    asset.value.metadata.pricing.cost = Number(asset.value.metadata.pricing.costCurrency[0].cost);
                    asset.value.metadata.pricing.currency = asset.value.metadata.pricing.costCurrency[0].currency;
                }

                if (asset.value.metadata.pricing.calculationScheme === 'Request Dependent') {
                    asset.value.metadata.pricing.cost = null;
                }
            }

            asset.value.policies = getAccessPoliciesJSON();

            const payload = {
                ...R.clone(asset.value),
                distribution: {},
                structure: { otherConcepts: [], primaryConcept: null },
            };
            if (payload.metadata && payload.metadata.pricing && payload.metadata.pricing.costCurrency) {
                delete payload.metadata.pricing.costCurrency;
            }

            if (asset.value && asset.value.id) {
                axiosRunner
                    .exec(AssetsAPI.updateAsset(asset.value.id, payload as any))
                    .then(() => {
                        (root as any).$toastr.s('Model Asset successfully updated!', 'Success');
                        root.$router.push({ name: 'assets' });
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Model Asset could not be updated!', 'Error');
                    });
            } else {
                axiosRunner
                    .exec(AssetsAPI.createAsset(payload as any))
                    .then(() => {
                        (root as any).$toastr.s('Model successfully registered!', 'Success');
                        root.$router.push({ name: 'assets' });
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Model could not be registered!', 'Error');
                    });
            }
        };

        const saveAllowed = computed(() => {
            return !(
                !validForms.value.general ||
                !validForms.value.model ||
                (showLicensing.value &&
                    metadata.licensing &&
                    (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing)) ||
                (isFeatureEnabled('asset.pricing.metadata') &&
                    metadata.pricing &&
                    customLicense.value &&
                    accessLevel.value !== 'Confidential' &&
                    accessLevel.value !== 'Public' &&
                    !validForms.value.pricing)
            );
        });

        const submitForms = () => {
            validForms.value = {
                general: false,
                model: false,
                accessLevelCopyrightOwner: false,
                licensing: false,
                pricing: false,
            };
            (root as any).$formulate.submit('general');
            (root as any).$formulate.submit('model');

            if (showLicensing.value && metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (!accessLevel.value || accessLevel.value === 'Confidential') {
                    validForms.value.licensing = true;
                } else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }
            if (
                isFeatureEnabled('asset.pricing.metadata') &&
                metadata.pricing &&
                customLicense.value &&
                accessLevel.value &&
                accessLevel.value !== 'Confidential' &&
                accessLevel.value !== 'Public'
            ) {
                (root as any).$formulate.submit('pricing');
            }
        };
        const saved = ref(false);
        const formSubmitted = (name: string) => {
            saveClicked.value = true;
            validForms.value[name] = true;

            if (saveAllowed.value) {
                saveChanges();
                saved.value = true;
            } else {
                contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        const resetLicenseAndPricing = (level: any) => {
            if (level) {
                if (level === 'Confidential') {
                    asset.value.metadata.license.license = null;
                    asset.value.metadata.license.copyrightOwner = null;
                    asset.value.metadata.license.link = null;
                    asset.value.metadata.license.derivation = null;
                    asset.value.metadata.license.attribution = null;
                    asset.value.metadata.license.reproduction = null;
                    asset.value.metadata.license.distribution = null;
                    asset.value.metadata.license.shareAlike = null;
                    asset.value.metadata.license.reContext = null;
                    asset.value.metadata.license.offlineRetention = null;
                    asset.value.metadata.license.targetPurpose = null;
                    if (asset.value.metadata.pricing) {
                        asset.value.metadata.pricing.cost = null;
                        asset.value.metadata.pricing.currency = null;
                        asset.value.metadata.pricing.paymentMethod = null;
                        asset.value.metadata.pricing.calculationScheme = null;
                    }
                } else if (level === 'Public' && asset.value.metadata.pricing) {
                    asset.value.metadata.pricing.cost = null;
                    asset.value.metadata.pricing.currency = null;
                    asset.value.metadata.pricing.paymentMethod = null;
                    asset.value.metadata.pricing.calculationScheme = null;
                } else if (level === 'Private' && asset.value.metadata.license.license !== 'Custom') {
                    asset.value.metadata.license.license = 'Custom';
                    checkLicense({ id: 'Custom', label: 'Custom' });
                }
            }
        };

        const retrieveModelDetails = () => {
            if (!props.id) return;
            loading.value = true;
            if (props.status && props.status === 'draft') {
                // means that there is no model asset created yet, we have data only for the model file
                axiosRunner.exec(AssetsAPI.getModelAssetDetails(id.value)).then((res: any) => {
                    assetStatus.value = 'draft';
                    asset.value.name = res.data.filename;

                    const library = res.data.attributes.type.split('-')[0];
                    const type = res.data.attributes.type.split('-')[1];
                    asset.value.metadata.model = {
                        library,
                        type,
                        purpose: res.data.attributes.purpose,
                        name: res.data.filename,
                        algorithm: res.data.attributes.name,
                    };

                    if (res.data.attributes.feature_order) {
                        featuresRef.value = res.data.attributes.feature_order;
                    }
                    if (res.data.attributes.features_encoded) {
                        encodedFeaturesRef.value = res.data.attributes.features_encoded;
                    }

                    asset.value.source = res.data.attributes.isUploadedByUser ? 'Upload' : 'Platform';
                    loading.value = false;
                });
            } else {
                axiosRunner.exec(AssetsAPI.getAsset(parseInt(id.value, 10))).then((res: any) => {
                    assetStatus.value = res.data.status;
                    asset.value.id = res.data.id;
                    asset.value.status = res.data.status;
                    asset.value.name = res.data.name;
                    asset.value.description = res.data.description;
                    asset.value.metadata.model = res.data.metadata.model;
                    asset.value.metadata.license = res.data.metadata.license;
                    asset.value.metadata.general = res.data.metadata.general;
                    asset.value.metadata.distribution = res.data.metadata.distribution;
                    asset.value.version = res.data.version;
                    asset.value.source = res.data.metadata.model.isUploadedByUser ? 'Upload' : 'Platform';
                    asset.value.metadata.pricing = res.data.metadata.pricing;
                    asset.value.metadata.pricing.costCurrency = [
                        {
                            cost: res.data.metadata.pricing.cost,
                            currency: res.data.metadata.pricing.currency,
                        },
                    ];

                    if (res.data.metadata.model.featureOrder) {
                        featuresRef.value = res.data.metadata.model.featureOrder;
                    }
                    if (res.data.metadata.model.encodedFeatures) {
                        encodedFeaturesRef.value = res.data.metadata.model.featureOrder;
                    }

                    accessLevel.value = res.data.metadata.license.accessLevel;
                    copyrightOwner.value = res.data.metadata.license.copyrightOwner;

                    loading.value = false;
                });
            }
        };

        retrieveModelDetails();

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => resetLicenseAndPricing(level),
        );

        return {
            contentRef,
            accessLevel,
            copyrightOwner,
            customLicense,
            cancel,
            asset,
            metadata,
            getLicensingSchema,
            getPricingSchema,
            accessLevelOptions,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            saveAllowed,
            saveClicked,
            isFeatureEnabled,
            showLicensing,
            modelSourceOptions,
            modelTypeOptions,
            modelPurposeOptions,
            modelLibraryOptions,
            featuresRef,
            loading,
            pageTitle,
            readOnly,
            StatusCode,
            assetStatus,
            encodedFeaturesRef,
        };
    },
});
