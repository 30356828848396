







































































































































































































































import * as R from 'ramda';
import { reactive, computed, watch } from '@vue/composition-api';
import AccessPolicyFieldSelect from './AccessPolicyFieldSelect.vue';
import AccessPolicyOperantSelect from './AccessPolicyOperantSelect.vue';
import AccessPolicyValueSelect from './AccessPolicyValueSelect.vue';
import AccessPolicyPreview from './AccessPolicyPreview.vue';
import AccessPolicyEditNode from './AccessPolicyEditNode.vue';
import { ExceptionPolicy, IndividualCondition, ConditionValue } from '../models';
import { Field, FieldType, Operant } from '../constants';

export default {
    name: 'AccessPolicyEdit',
    props: {
        defaultPolicyEffect: {
            type: Boolean,
            required: false,
        },
        policy: {
            type: ExceptionPolicy,
            required: false,
        },
    },
    components: {
        AccessPolicyFieldSelect,
        AccessPolicyOperantSelect,
        AccessPolicyValueSelect,
        AccessPolicyPreview,
        AccessPolicyEditNode,
    },
    setup(props: any, { emit }: { emit: any }) {
        const newPolicy = reactive<{
            currentField: any;
            currentOperator: any;
            currentValue: any;
            otherConditions: any;
        }>({
            currentField: null,
            currentOperator: null,
            currentValue: null,
            otherConditions: [],
        });

        const copyConditions = (conditions: IndividualCondition[]) => {
            const newConditions: IndividualCondition[] = [];
            conditions.forEach((condition) => {
                newConditions.push(new IndividualCondition(condition.field, condition.operant, condition.fieldValues));
            });
            return newConditions;
        };

        watch(
            () => props.policy,
            (policy: ExceptionPolicy) => {
                if (policy) {
                    newPolicy.otherConditions = copyConditions(policy.conditions);
                }
            },
        );

        const policyEffect = computed<boolean>(() => (props.policy ? props.policy.allow : props.defaultPolicyEffect));
        const isConditionValid = (field: Field | null, operant: Operant | null, value: any[] | null) => {
            return field != null && operant != null && value != null && value.length > 0;
        };

        const canAddCondition = computed(() =>
            isConditionValid(newPolicy.currentField, newPolicy.currentOperator, newPolicy.currentValue),
        );

        const validConditions = computed(() => {
            const conditions: IndividualCondition[] = [];
            newPolicy.otherConditions.forEach((condition: IndividualCondition) => {
                if (condition.isValid()) {
                    conditions.push(condition);
                }
            });
            return conditions;
        });

        const currentActiveConditions = computed(() => {
            const conditions = [...validConditions.value];

            if (canAddCondition.value) {
                conditions.push(
                    new IndividualCondition(newPolicy.currentField, newPolicy.currentOperator, newPolicy.currentValue),
                );
            }
            return conditions;
        });

        const policyErrors = computed(() => {
            const warnings: string[] = [];

            const fieldOperantKeys: any = {};
            const fieldKeys: any = {};
            currentActiveConditions.value.forEach((condition: IndividualCondition) => {
                const fieldOperantKey = `${condition.field.key}_${condition.operant.key}`;

                if (fieldOperantKey in fieldOperantKeys) {
                    fieldOperantKeys[fieldOperantKey].count++;
                } else {
                    fieldOperantKeys[fieldOperantKey] = {
                        field: condition.field,
                        operant: condition.operant,
                        count: 1,
                    };
                }

                if (condition.field.key in fieldKeys) {
                    const combinations = { ...fieldKeys[condition.field.key].combinations };
                    condition.fieldValues.forEach((val: ConditionValue) => {
                        const valueIds: string[] | string = val.getIds();
                        let ids: string[] = [];
                        if (!R.is(Array, valueIds)) {
                            ids = [valueIds as string];
                        } else {
                            ids = valueIds as string[];
                            ids.push(val.id);
                        }
                        ids.forEach((id: string) => {
                            if (id in combinations && !combinations[id].includes(condition.operant)) {
                                combinations[id].push(condition.operant);
                            } else if (!(val.id in combinations)) {
                                combinations[id] = [condition.operant];
                            }
                        });
                    });
                    fieldKeys[condition.field.key].combinations = combinations;
                } else {
                    const combinations = {};
                    condition.fieldValues.forEach((val: ConditionValue) => {
                        const valueIds: string[] | string = val.getIds();
                        let ids: string[] = [];
                        if (!R.is(Array, valueIds)) {
                            ids = [valueIds as string];
                        } else {
                            ids = valueIds as string[];
                            ids.push(val.id);
                        }
                        ids.forEach((id: string) => {
                            combinations[id] = [condition.operant];
                        });
                    });
                    fieldKeys[condition.field.key] = {
                        field: condition.field,
                        combinations,
                    };
                }
            });

            // same field and operant
            Object.values(fieldOperantKeys).forEach((obj: any) => {
                if (obj.count > 1) {
                    warnings.push(
                        `${obj.count} conditions are checking for '${
                            obj.operant.displayLabel
                        }' on the field '${obj.field.displayLabel.toLowerCase()}'`,
                    );
                }
            });

            // opposite condition
            Object.values(fieldKeys).forEach((obj: any) => {
                Object.keys(obj.combinations).forEach((valueKey: any) => {
                    const operants = obj.combinations[valueKey];
                    if (operants.length > 1) {
                        operants.forEach((operant: Operant) => {
                            const { opposite } = operant;
                            const val = obj.field.getValue(valueKey)[0].label;
                            if (opposite !== null && operants.includes(opposite)) {
                                warnings.push(
                                    `Conflicting conditions are checking for both '${operant.displayLabel.toLowerCase()}' and '${opposite.displayLabel.toLowerCase()}' for ${obj.field.displayLabel.toLowerCase()} '${val}'`,
                                );
                            }
                        });
                    }
                });
            });

            return warnings;
        });

        const canAddPolicyErrorMessage = computed(() => {
            let conditionErrorMessage = null;
            newPolicy.otherConditions.forEach((condition: IndividualCondition) => {
                if (!condition.isValid()) {
                    conditionErrorMessage = 'One or more conditions are not completed';
                }
            });
            if (conditionErrorMessage) {
                return conditionErrorMessage;
            }

            if (!canAddCondition.value && newPolicy.otherConditions.length === 0) {
                return 'At least one condition must be specified to create a new policy';
            }

            if (policyErrors.value.length > 0) {
                return 'Cannot be saved until consistency check errors are resolved';
            }
            return null;
        });
        const fields = computed(() => Field.all());
        const isNew = computed(() => !props.policy);

        const policyPreview = computed(() => {
            const conditions = [...currentActiveConditions.value];
            return new ExceptionPolicy(policyEffect.value, copyConditions(conditions));
        });

        const clearAddConditionForm = () => {
            newPolicy.currentField = null;
            newPolicy.currentOperator = null;
            newPolicy.currentValue = null;
        };

        const clearAddPolicyForm = () => {
            clearAddConditionForm();
            newPolicy.otherConditions = [];
        };

        const addCondition = () => {
            if (canAddCondition.value) {
                const newCondition = new IndividualCondition(
                    newPolicy.currentField,
                    newPolicy.currentOperator,
                    newPolicy.currentValue,
                );
                newPolicy.otherConditions.push(newCondition);
                clearAddConditionForm();
            }
        };

        const savePolicy = () => {
            if (!canAddPolicyErrorMessage.value) {
                addCondition();
                const policy = new ExceptionPolicy(policyEffect.value, copyConditions([...validConditions.value]));
                clearAddPolicyForm();
                emit('save', policy);
            }
        };

        const deleteNewPolicyCondition = (conditionIndex: number) => {
            newPolicy.otherConditions.splice(conditionIndex, 1);
        };

        const updateField = (id: number, field: Field) => {
            const newConditions = [...newPolicy.otherConditions];
            newConditions[id] = new IndividualCondition(field, newConditions[id].operant, newConditions[id].values);
            newPolicy.otherConditions = newConditions;
        };

        const updateOperant = (id: number, operant: Operant) => {
            const newConditions = [...newPolicy.otherConditions];
            newConditions[id] = new IndividualCondition(newConditions[id].field, operant, newConditions[id].values);
            newPolicy.otherConditions = newConditions;
        };

        const updateValue = (id: number, values: ConditionValue[]) => {
            const newConditions = [...newPolicy.otherConditions];
            newConditions[id] = new IndividualCondition(newConditions[id].field, newConditions[id].operant, values);
            newPolicy.otherConditions = newConditions;
        };

        return {
            fields,
            FieldType,
            newPolicy,
            canAddCondition,
            canAddPolicyErrorMessage,
            policyEffect,
            isNew,
            policyPreview,
            policyErrors,
            clearAddConditionForm,
            clearAddPolicyForm,
            addCondition,
            savePolicy,
            deleteNewPolicyCondition,
            emit,
            updateField,
            updateOperant,
            updateValue,
        };
    },
};
