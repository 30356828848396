var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-12 p-4 text-sm bg-neutral-100 rounded my-2"},[_c('div',{staticClass:"flex flex-col space-y-4"},[_vm._l((_vm.newPolicy.otherConditions),function(condition,idx){return _c('div',{key:idx,staticClass:"flex flex-col space-y-2"},[_c('access-policy-edit-node',{attrs:{"identifier":idx,"field":condition.field,"operant":condition.operant,"values":condition.fieldValues,"isValid":condition.isValid()},on:{"updated-field":_vm.updateField,"updated-operant":_vm.updateOperant,"updated-value":_vm.updateValue,"delete":function($event){return _vm.deleteNewPolicyCondition(idx)}}}),_vm._m(0,true)],1)}),_c('div',{staticClass:"flex flex-row justify-between space-x-2"},[_c('div',{staticClass:"flex flex-1 flex-row space-x-6 ml-1"},[_c('access-policy-field-select',{staticClass:"w-full my-auto",attrs:{"fields":_vm.fields},model:{value:(_vm.newPolicy.currentField),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentField", $$v)},expression:"newPolicy.currentField"}}),(_vm.newPolicy.currentField)?_c('access-policy-operant-select',{staticClass:"w-full my-auto",attrs:{"field":_vm.newPolicy.currentField},model:{value:(_vm.newPolicy.currentOperator),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentOperator", $$v)},expression:"newPolicy.currentOperator"}}):_c('div',{staticClass:"w-full"}),(_vm.newPolicy.currentField)?_c('access-policy-value-select',{staticClass:"w-full my-auto",attrs:{"field":_vm.newPolicy.currentField},model:{value:(_vm.newPolicy.currentValue),callback:function ($$v) {_vm.$set(_vm.newPolicy, "currentValue", $$v)},expression:"newPolicy.currentValue"}}):_c('div',{staticClass:"w-full"})],1),(
                    _vm.newPolicy.currentField !== null ||
                    _vm.newPolicy.currentOperator !== null ||
                    _vm.newPolicy.currentOperator != null
                )?_c('div',{staticClass:"cursor-pointer text-neutral-500 hover:text-red-700 my-auto",attrs:{"title":"Remove condition"},on:{"click":_vm.clearAddConditionForm}},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])]):_c('div',{staticClass:"w-4"})]),_c('button',{staticClass:"\n                m-2\n                w-full\n                tracking-wide\n                border-2\n                rounded\n                border-primary-700\n                text-primary-700\n                hover:bg-primary-100\n                cursor-pointer\n                disabled:cursor-not-allowed disabled:opacity-50\n            ",attrs:{"disabled":!_vm.canAddCondition,"title":_vm.canAddCondition
                    ? 'Add another condition'
                    : 'You need to complete the previous condition before adding another'},on:{"click":_vm.addCondition}},[_c('div',{staticClass:"justify-center flex flex-row space-x-2 p-1"},[_c('svg',{staticClass:"w-4 h-4 my-auto",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 6v6m0 0v6m0-6h6m-6 0H6"}})]),_vm._m(1)])])],2),_c('div',{staticClass:"flex flex-col space-y-6"},[(_vm.policyErrors.length > 0)?_c('div',{staticClass:"text-orange-700 bg-orange-100 flex flex-col space-y-2 py-1 px-2"},[_c('div',{staticClass:"font-bold"},[_vm._v("Consistency check errors")]),_vm._l((_vm.policyErrors),function(error,idx){return _c('div',{key:idx,staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('div',{staticClass:"mt-1"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('div',[_vm._v(_vm._s(error))])])])})],2):_vm._e(),_c('div',{staticClass:"rounded py-1 px-2",class:{
                'bg-green-100 text-green-900': _vm.policyEffect,
                'bg-red-100 text-red-900': !_vm.policyEffect,
            },attrs:{"title":"Policy exception preview"}},[(_vm.policyPreview.conditions.length > 0)?_c('div',{staticClass:"flex flex-row space-x-2"},[(_vm.policyPreview.allow)?_c('div',{staticClass:"text-green-700 my-auto"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_c('div',{staticClass:"text-red-700 my-auto"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('access-policy-preview',{attrs:{"policy":_vm.policyPreview}})],1):_c('div',{staticClass:"italic"},[_vm._v("You need to define at least one condition for policy to be added")])]),_c('div',{staticClass:"flex flex-row space-x-6 ml-auto"},[_c('button',{staticClass:"\n                    bg-neutral-300\n                    opacity-50\n                    rounded\n                    py-1\n                    px-3\n                    hover:opacity-100\n                    text-neutral-800\n                    cursor-pointer\n                ",on:{"click":function($event){_vm.clearAddPolicyForm(), _vm.emit('cancel')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"\n                    text-white\n                    rounded\n                    py-1\n                    px-6\n                    hover:opacity-100\n                    disabled:opacity-50 disabled:cursor-not-allowed\n                ",class:{
                    'bg-green-700': _vm.policyEffect,
                    'bg-red-700': !_vm.policyEffect,
                },attrs:{"disabled":_vm.canAddPolicyErrorMessage,"title":_vm.canAddPolicyErrorMessage},on:{"click":_vm.savePolicy}},[(_vm.isNew)?[_vm._v("Create policy exception")]:_vm._e(),(!_vm.isNew)?[_vm._v("Update policy exception")]:_vm._e()],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n                    m-2\n                    tracking-wide\n                    uppercase\n                    flex flex-row\n                    space-x-2\n                    p-1\n                    text-primary-700\n                    w-full\n                    border-t border-b border-neutral-300\n                "},[_c('span',{staticClass:"mx-auto"},[_vm._v("AND")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("AND "),_c('span',{staticClass:"opacity-75"},[_vm._v("(add another condition)")])])}]

export { render, staticRenderFns }